import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import LastSeen from "../components/LastSeen";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import notify from "../utils/notify";
import { updateContract } from "../api";

const DashboardContracts = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { contracts, getContratcsList, setUnreadContracts } = useContext(
    DataContext
  );
  const { auth } = useContext(AuthContext);

  const fetchContracts = async (accountId) => {
    const accountType = auth?.client ? "client" : "talent";
    const response = await getContratcsList(accountId, accountType);
    const unreadContracts = response.filter(
      ({ status }) => status === "pending"
    );
    await setUnreadContracts(unreadContracts.length || null);
    setLoading(false);
  };

  useEffect(() => {
    const accountId = window.localStorage.getItem("account_id");
    fetchContracts(accountId);
    return () => setLoading(true);
  }, []);

  const handleAcceptContract = async (id) => {
    try {
      setSubmitting(true);
      const payload = {
        status: "active",
      };
      await updateContract(id, payload);
      setSubmitting(false);
      setLoading(true);
      await getContratcsList(auth?.talent?.account_id, "talent");
      setLoading(false);
      notify("Contract accepted successfully", "success");
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">My Contracts</h3>
                </div>
              </div>
              <div className="bg-white p-2 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="">
                      <div className="table-responsive ">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Job Title
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                {auth?.client ? "Talent Name" : "Hired By"}
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Hours
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                {auth?.client ? "Cost" : "Your Fee"}
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Created
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Feedback
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              [1, 2, 3, 4, 5].map((index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                    <Skeleton width={200} height={26} />
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={150} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-70">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-70">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-70">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-70">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                </tr>
                              ))
                            ) : contracts ? (
                              contracts.map((contract, index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th
                                    scope="row"
                                    className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                  >
                                    <div className="">
                                      <Link
                                        to={`/job-details/${contract.jobId}`}
                                        state={{ id: contract?.jobId }}
                                        className={`font-size-4 mb-0 ${
                                          contract?.status === "pending"
                                            ? "font-weight-semibold text-black-2"
                                            : "font-weight-normal text-gray"
                                        }`}
                                      >
                                        {contract?.jobTitle || "N/A"}
                                      </Link>
                                    </div>
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    {auth?.client ? (
                                      <Link
                                        to={`/candidate-profile/${contract?.talent}`}
                                        state={{ id: contract?.talent }}
                                        className={`btn btn-outline font-size-4 ${
                                          contract?.status === "pending"
                                            ? "font-weight-semibold text-black-2"
                                            : "font-weight-normal text-gray"
                                        } focus-reset`}
                                      >
                                        {contract?.talent}
                                      </Link>
                                    ) : (
                                      <h3
                                        className={`font-size-4 ${
                                          contract?.status === "pending"
                                            ? "font-weight-semibold text-black-2"
                                            : "font-weight-normal text-gray"
                                        } mb-0`}
                                      >
                                        {contract?.client}
                                      </h3>
                                    )}
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <h3
                                      className={`font-size-4 ${
                                        contract?.status === "pending"
                                          ? "font-weight-semibold text-black-2"
                                          : "font-weight-normal text-gray"
                                      } mb-0`}
                                    >
                                      {contract?.hours}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 ${
                                        contract?.status === "pending"
                                          ? "font-weight-semibold text-black-2"
                                          : "font-weight-normal text-gray"
                                      } mb-0`}
                                    >
                                      {auth?.client
                                        ? contract?.total.toFixed(2)
                                        : contract?.talentFee.toFixed(2)}{" "}
                                      {contract?.currency}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 ${
                                        contract?.status === "pending"
                                          ? "font-weight-semibold text-black-2"
                                          : "font-weight-normal text-gray"
                                      } mb-0`}
                                    >
                                      <LastSeen
                                        date={
                                          new Date(contract?.created.toDate())
                                        }
                                      />
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 ${
                                        contract?.status === "pending"
                                          ? "font-weight-semibold text-black-2"
                                          : "font-weight-normal text-gray"
                                      } mb-0`}
                                    >
                                      {contract?.status}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 ${
                                        contract?.status === "pending"
                                          ? "font-weight-semibold text-black-2"
                                          : "font-weight-normal text-gray"
                                      } mb-0`}
                                    >
                                      {contract?.talent?.rating ||
                                        "No feedback"}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-90">
                                    <Link
                                      to={`/contract-details/${contract.id}`}
                                      state={{ id: contract?.id }}
                                      className="text-uppercase font-weight-semibold font-size-3 text-green focus-reset text-nowrap"
                                    >
                                      <i className="fa fa-eye mr-2"></i>
                                      <span>Details</span>
                                    </Link>
                                    {/* {auth?.talent && contract?.status === "pending" ? (
                                    <button
                                      type="button"
                                      onClick={() => handleAcceptContract(contract.id)}
                                      className="border-0 bg-transparent text-uppercase font-weight-semibold font-size-3 text-shamrock focus-reset ml-4 text-nowrap pt-2"
                                    >
                                      <i className="fa fa-check-circle mr-2"></i>
                                      <span>{submitting ? "..." : "Accept"}</span>
                                    </button>
                                  ) : null} */}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="border border-color-2">
                                <td
                                  scope="row"
                                  colSpan={7}
                                  className="pl-6 border-0 py-7 pr-0"
                                >
                                  <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                    No contracts found at the moment
                                  </h4>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardContracts;
